<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <input type="file" :class="classes" v-bind="attributes" @change="fileChanged" />
</template>

<script>
export default {
  name: 'FormFile',
  props: {
    model: { type: Object, required: true },
    name: { type: String, required: true },
    size: { type: String, default: undefined },
    accept: { type: String, default: undefined },
    required: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    classes () {
      const classes = ['form-control']

      if (this.size) classes.push('input-' + this.size)

      return classes.join(' ')
    },
    attributes () {
      return {
        id: this.name,
        name: this.name,
        accept: this.accept,
        multiple: this.multiple,
        readonly: this.readonly,
        disabled: this.attrDisabled,
        required: !this.attrDisabled && this.required
      }
    },
    attrDisabled () { return this.readonly ? true : this.disabled }
  },
  methods: {
    fileChanged (event) {
      const { target: { files } } = event

      if (!files.length) {
        this.model.value = null
        return
      }

      this.model.value = this.multiple ? files : files[0]
    }
  }
}
</script>
