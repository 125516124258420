<template>
  <div class="form-group" :class="{ 'required': required, 'has-error': errors.length }">
    <label :for="name">{{ titleValue }}</label>
    <div class="additional-info" v-if="$slots['info']">
      <slot name="info"/>
    </div>
    <form-file v-bind="$props"/>
    <p class="help-block info" v-if="$slots['help']">
      <slot name="help"/>
    </p>
    <p class="help-block error" v-for="(error, i) in errors" :key="`${name}-error-${i}`">{{ error }}</p>
  </div>
</template>

<script>
import FormFile from '@/views/components/form/FormFile'

export default {
  name: 'FormGroupFile',
  components: {
    FormFile
  },
  props: {
    title: { type: String, default: '' },
    ...FormFile.props
  },
  computed: {
    errors () { return this.model.errors },
    titleValue () { return this.title ? this.title : this.$helpers.Str.titleCase(this.name) }
  }
}
</script>
