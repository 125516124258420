import { render, staticRenderFns } from "./FormGroupFile.vue?vue&type=template&id=3579ff9d&"
import script from "./FormGroupFile.vue?vue&type=script&lang=js&"
export * from "./FormGroupFile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports