<template>
  <div>
    <portal to="page-top-title">New List</portal>
    <portal to="page-top-right"/>

    <box-form action="create" data-prop="formData" url="/contact-lists" redirect="contactLists-list" loader>
      <template slot="content">
        <row>
          <column-input>
            <form-group-input name="name" :model="formData.name" :min="3" required/>
          </column-input>
          <column-input>
            <form-group-select name="category_id" title="Category" :model="formData.category_id"
                               :options="categories" :disabled="!categories.length" required/>
          </column-input>
          <column-input :md="2" :xs="6">
            <form-group-check name="active" :model="formData.active"/>
          </column-input>
          <column-input :md="2" :xs="6">
            <form-group-check name="live_list" :model="formData.live_list"/>
          </column-input>
        </row>
        <row>
          <column-input>
            <form-group-input name="custom_id" :model="formData.custom_id" title="Custom ID"/>
          </column-input>
          <column-input>
            <form-group-input type="number" name="price" :model="formData.price" :min="0" :step="0.01" :max="9999999.99"/>
          </column-input>
        </row>
        <row v-if="!isLive">
          <column-input :md="8">
            <form-group-file name="file" :model="formData.file" accept=".csv" required/>
          </column-input>
          <column-input>
            <form-group-check name="no_deduplication" :model="formData.no_deduplication">
              <p slot="help"></p>
            </form-group-check>
          </column-input>
        </row>
        <row v-if="!isLive">
          <column-input :md="12" :sm="12">
            <div class="form-group">
              <strong>Deduplication</strong>
              <p class="help-block">If Contact with an Email already exists in one of the Lists – we will not create it again in a new List. You can override existing Contacts with "No Deduplication" option.</p>
            </div>
            <div class="form-group">
              <strong>CSV File columns order</strong>
              <p class="help-block">
                <template v-for="(col, i) in fileColumns">
                  <span :key="`file-column-${i}-comma`" v-if="i !== 0">, </span>
                  <code :key="`file-column-${i}`">{{ col }}</code>
                </template>
              </p>
            </div>
          </column-input>
        </row>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import FormGroupInput from '@/views/components/form/FormGroupInput'
import FormGroupCheck from '@/views/components/form/FormGroupCheck'
import FormGroupSelect from '@/views/components/form/FormGroupSelect'
import FormGroupFile from '@/views/components/form/FormGroupFile'
import Form from '@/modules/utils/Form'

export default {
  name: 'ContactListsNew',
  metaInfo () {
    return { title: 'Lists | New' }
  },
  components: {
    BoxForm,
    FormGroupInput,
    FormGroupCheck,
    FormGroupSelect,
    FormGroupFile
  },
  data () {
    return {
      categories: [],
      formData: {
        category_id: Form.types.select(),
        name: Form.types.input(),
        price: Form.types.number(),
        custom_id: Form.types.input(),
        active: Form.types.boolean(true),
        live_list: Form.types.boolean(),
        no_deduplication: Form.types.boolean(),
        file: Form.types.file()
      },
      fileColumns: [
        'email', 'first_name', 'last_name', 'phone_number', 'state_code', 'city',
        'zip_code', 'address', 'gender', 'ip', 'timestamp', 'source_url', 'user_agent',
        'os', 'device', 'browser', 'external_id', 'c1', 'c2', 'c3', 'c4', 'c5'
      ]
    }
  },
  computed: {
    isLive () { return Boolean(this.formData.live_list.value) }
  },
  created () {
    this.loadCategories()
  },
  watch: {
    isLive (val) { this.isLiveChanged(val) }
  },
  methods: {
    loadCategories () {
      this.$http
        .get('/contact-lists/categories', { params: { no_pages: 1 } })
        .then((res) => {
          const { data: { data } } = res

          this.categories = data.map((category) => { return { id: category.id, name: category.name } })

          this.categoryCheckSelected()
        })
    },
    categoryCheckSelected () {
      const queryCatId = Number(this.$route.query.category)

      if (isNaN(queryCatId)) return

      const category = this.categories.find((category) => category.id === queryCatId)

      if (category) this.formData.category_id = Form.types.select(category)
    },
    isLiveChanged (isLive) {
      this.formData.file.hidden = isLive
      this.formData.no_deduplication.hidden = isLive
    }
  }
}
</script>
